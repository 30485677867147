import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticlesComponent } from './articles/articles.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';

const routes: Routes = [
  { path: '', redirectTo: '/tab/invalid', pathMatch: 'full' },
  { path: 'hireme', redirectTo: '/tab/about', pathMatch: 'full'},
  { path: 'corner', redirectTo: '/tab/home', pathMatch: 'full'},

  { path: 'articles/:cat', component: ArticlesComponent },
  { path: 'tab/:cat', component: DashboardComponent },
  { path: 'detail/:id', component: ArticleDetailComponent },

  { path: '**', redirectTo: '/tab/invalid', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
    )
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
