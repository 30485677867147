function createShader(gl, type, source) {
  var shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    const message = gl.getShaderInfoLog(shader);
    console.log(message);
    const glShaderSource = gl.getShaderSource(shader);
    let parsed = glShaderSource.split('\n').reduce((acc,el,index)=>(index>1?acc+'\n'+(index+1)+'\t'+el:index+'\t'+acc+'\n'+(index+1)+'\t'+el));
    console.log(parsed);
    throw Error(message);
  }
  return shader;
}

function createProgram(gl, vertexSource, fragmentSource) {
  let program = gl.createProgram();
  let vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexSource);
  let fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentSource);
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    const message = gl.getProgramInfoLog(program);
    throw Error(message);
  }
  let wrapper = {program: program};
  let numAttributes = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);
  for (let i = 0; i < numAttributes; i++) {
    let attribute = gl.getActiveAttrib(program, i);
    wrapper[attribute.name] = gl.getAttribLocation(program, attribute.name);
  }
  var numUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
  for (let i$1 = 0; i$1 < numUniforms; i$1++) {
    let uniform = gl.getActiveUniform(program, i$1);
    wrapper[uniform.name] = gl.getUniformLocation(program, uniform.name);
  }

  return wrapper;
}

function createTexture(gl, filter, data, width, height) {
  var texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, filter);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, filter);
  if (data instanceof Uint8Array) {
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, data);
  } else {
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, data);
  }
  gl.bindTexture(gl.TEXTURE_2D, null);
  return texture;
}

function bindTexture(gl, texture, unit) {
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, texture);
}

function createBuffer(gl, data) {
  var buffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
  return buffer;
}

function bindAttribute(gl, buffer, attribute, numComponents) {
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.enableVertexAttribArray(attribute);
  gl.vertexAttribPointer(attribute, numComponents, gl.FLOAT, false, 0, 0);
}

function bindFramebuffer(gl, framebuffer, texture) {
  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
  if (texture) {
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
  }
}

var drawVert = `
precision mediump float;
attribute float a_index;
uniform sampler2D u_particles;
uniform float u_particles_res;
varying vec2 v_particle_pos;
void main()
{
    vec4 color = texture2D(u_particles, vec2(
        fract(a_index / u_particles_res),
        floor(a_index / u_particles_res) / u_particles_res));
    // decode current particle position from the pixel's RGBA value
    v_particle_pos = vec2(
        color.r / 255.0 + color.b,
        color.g / 255.0 + color.a);
    gl_PointSize = 2.0; // minimum size to stay below the nyquist frequency
    gl_Position = vec4(2.0 * v_particle_pos.x - 1.0, 1.0 - 2.0 * v_particle_pos.y, 0, 1);
}
`;

var drawFrag = `
precision mediump float;
uniform mat4 luc;
uniform vec4 lucQ;
uniform vec4 lucL;
uniform vec4 mouseData;
uniform sampler2D u_conv_texture;
uniform vec2 u_wind_res;
uniform vec2 u_wind_min;
uniform vec2 u_wind_max;
uniform sampler2D u_color_ramp;
varying vec2 v_particle_pos;
void main() {
    float facX = u_wind_res.x > u_wind_res.y ? u_wind_res.x / u_wind_res.y : 1.0;
    float facY = u_wind_res.x > u_wind_res.y ? 1.0 : u_wind_res.y / u_wind_res.x;
  
    // compute for 8 tracers
    vec2 vel = vec2(0.0, 0.0);
    float gamma = 0.01;
    float epsilon = 2.0;
    for (int i = 0; i < 4; ++i) {
        float w1 = lucQ[i];
        float delta0 = facX * (luc[i][0] - v_particle_pos[0]);
        float delta1 = facY * (luc[i][1] - v_particle_pos[1]);
        float d2 = delta0 * delta0 + delta1 * delta1;
        float extinction = exp(-d2 / gamma);
        vel[0] += extinction * delta1 * epsilon * w1;
        vel[1] += extinction * (delta0 * epsilon * w1);

        w1 = lucL[i];
        delta0 = facX * (luc[i][2] - v_particle_pos[0]);
        delta1 = facY * (luc[i][3] - v_particle_pos[1]);
        d2 = delta0 * delta0 + delta1 * delta1;
        extinction = exp(-d2 / gamma);
        vel[0] += extinction * delta1 * epsilon * w1;
        vel[1] += extinction * (delta0 * epsilon * w1);
    }
    // mouse
    vec2 mouseVel = vec2(0.0, 0.0);
    {
        float w1 = mouseData[2];
        float w2 = mouseData[3];
        float delta0 = facX * (mouseData[0] - v_particle_pos[0]);
        float delta1 = facY * (mouseData[1] - v_particle_pos[1]);
        float d2 = delta0 * delta0 + delta1 * delta1;
        float extinction = exp(-d2 / gamma);
        mouseVel.x = extinction * delta1 * epsilon * w1;
        mouseVel.y = extinction * delta0 * epsilon * w2;
        vel[0] += mouseVel.x;
        vel[1] += mouseVel.y;
    }
    vel[0] -= 0.05; // correct the added velocity to prevent stationary particles
    vec2 velocity = mix(u_wind_min, u_wind_max, vel);
    float speed_t = length(velocity) / length(u_wind_max);
    vec3 s2 = mix(vec3(0.0), vec3(1.0), texture2D(u_conv_texture, v_particle_pos).rgb);
    // float speed2 = sqrt(s2.r*s2.r + s2.g*s2.g + s2.b*s2.b);
    float speed2 = s2.r+s2.g+s2.b;
    // speed2 = speed_t;
    // color ramp is encoded in a 16x16 texture
    vec2 ramp_pos = vec2(
        fract(16.0 * speed2),
        floor(16.0 * speed2) / 16.0);
    vec4 outputColor = texture2D(u_color_ramp, ramp_pos);

    float speed_m = length(mix(u_wind_min, u_wind_max, mouseVel)) / length(u_wind_max);

    float powSpeedT = 10.0 * (speed_t);
    float originalR = outputColor[0];
    float newR = max(powSpeedT, outputColor[0]);
    {
      // Here, customize the difference between the mouse vortex and the other vortices.
      float powSpeedM = 10.0 * (speed_m);
      // outputColor[1] = min(powSpeedM, outputColor[1]);
      // outputColor[2] = max(powSpeedM, outputColor[2]);
      outputColor[0] = originalR + (newR-originalR); // * clamp(powSpeedT-powSpeedM, 0.0, 1.0);
    }

    gl_FragColor = outputColor;
}
`;

var quadVert = `
precision mediump float;
attribute vec2 a_pos;
varying vec2 v_tex_pos;
void main() {
    v_tex_pos = a_pos;
    gl_Position = vec4(1.0 - 2.0 * a_pos, 0, 1);
}
`;

var screenFrag = `
precision mediump float;
uniform sampler2D u_screen;
uniform float u_opacity;
varying vec2 v_tex_pos;
void main() {
    vec4 color = texture2D(u_screen, 1.0 - v_tex_pos);
    // a hack to guarantee opacity fade out even with a value close to 1.0
    gl_FragColor = vec4(floor(255.0 * color * u_opacity) / 255.0);
}
`;

var updateFrag = `
precision highp float;
uniform sampler2D u_particles;
uniform vec4 lucQ;
uniform vec4 lucL;
uniform vec4 mouseData;
uniform mat4 luc;
uniform vec2 u_wind_res;
uniform vec2 u_wind_min;
uniform vec2 u_wind_max;
uniform float u_rand_seed;
uniform float u_speed_factor;
uniform float u_drop_rate;
uniform float u_drop_rate_bump;
varying vec2 v_tex_pos;
// pseudo-random generator
const vec3 rand_constants = vec3(12.9898, 78.233, 4375.85453);
float rand(const vec2 co) {
    float t = dot(rand_constants.xy, co);
    return fract(sin(t) * (rand_constants.z + t));
}

// wind speed lookup; use manual bilinear filtering based on 4 adjacent pixels for smooth interpolation
vec2 lookup_wind(const vec2 uv) {
    vec2 px = 1.0 / u_wind_res;
    vec2 vc = (floor(uv * u_wind_res)) * px;
    float facX = u_wind_res.x > u_wind_res.y ? u_wind_res.x / u_wind_res.y : 1.0;
    float facY = u_wind_res.x > u_wind_res.y ? 1.0 : u_wind_res.y / u_wind_res.x;
    vec2 n = normalize(u_wind_res);
    float gamma = 0.02 * max(n.x, n.y);
    float epsilon = -2.0;
    vec2 vel = vec2(0.0, 0.0);
    for (int i = 0; i < 4; ++i) {
        float w1 = lucQ[i];
        float delta0 = facX * (luc[i][0] - vc[0]);
        float delta1 = facY * (luc[i][1] - vc[1]);
        float d2 = delta0 * delta0 + delta1 * delta1;
        float extinction = exp(-d2 / gamma);
        vel[0] += extinction * delta1 * epsilon * w1;
        vel[1] += extinction * (delta0 * epsilon * w1);

        w1 = lucL[i];
        delta0 = facX * (luc[i][2] - vc[0]);
        delta1 = facY * (luc[i][3] - vc[1]);
        d2 = delta0 * delta0 + delta1 * delta1;
        extinction = exp(-d2 / gamma);
        vel[0] += extinction * delta1 * epsilon * w1;
        vel[1] += extinction * (delta0 * epsilon * w1);
    }
    {
        float w1 = mouseData[2];
        float w2 = mouseData[3];
        float delta0 = facX * (mouseData[0] - vc[0]);
        float delta1 = facY * (mouseData[1] - vc[1]);
        float d2 = delta0 * delta0 + delta1 * delta1;
        float extinction = exp(-d2 / gamma);
        // vel[0] += extinction * delta1 * epsilon * w1;
        // vel[1] += extinction * delta0 * epsilon * w2;
    }
    vel[0] += 0.05; // prevent stationary particles far from vortices
    vec2 velocity = mix(u_wind_min, u_wind_max, vel);
    return velocity;
}

void main() {
    vec4 color = texture2D(u_particles, v_tex_pos);
    vec2 pos = vec2(
        color.r / 255.0 + color.b,
        color.g / 255.0 + color.a); // decode particle position from pixel RGBA
    vec2 velocity = mix(u_wind_min, u_wind_max, lookup_wind(pos));
    float speed_t = length(velocity) / length(u_wind_max);
    // take EPSG:4236 distortion into account for calculating where the particle moved
    float distortion = cos(radians(pos.y * 180.0 - 90.0)) + 0.1;
    // vec2 offset = vec2(velocity.x / 1.0, -velocity.y) * 0.0001 * u_speed_factor;
    vec2 offset = vec2(velocity.x * distortion, -velocity.y) * 0.0001 * u_speed_factor;
    // No distortion
    // update particle position, wrapping around the date line
    pos = fract(1.0 + pos + offset);
    // a random seed to use for the particle drop
    vec2 seed = (pos + v_tex_pos) * u_rand_seed;
    // drop rate is a chance a particle will restart at random position, to avoid degeneration
    float drop_rate = u_drop_rate + speed_t * u_drop_rate_bump;
    float drop = step(1.0 - drop_rate, rand(seed));
    vec2 random_pos = vec2(
        rand(seed + 1.3),
        rand(seed + 2.1));
    pos = mix(pos, random_pos, drop);
    // encode the new particle position back into RGBA
    gl_FragColor = vec4(
        fract(pos * 255.0),
        floor(pos * 255.0) / 255.0);
}
`;

var palette2 = {
  0.0: '#6a5b4e',
  0.1: '#826f69',
  0.2: '#9c897f',
  0.3: '#c4a898',
  0.4: '#d8c7a6',
  0.5: '#f1d6bb',
  0.6: '#f6e3ce',
  1.0: '#fff0d4'
};

var palette3 = {
  0.0: '#3288bd',
  0.1: '#66c2a5',
  0.2: '#abdda4',
  0.3: '#e6f598',
  0.4: '#fee08b',
  0.5: '#fdae61',
  0.6: '#f46d43',
  1.0: '#d53e4f'
};

var defaultRampColors = {
  // 1.0: "#d73027",
  // 0.4: "#f46d43",
  // 0.3: "#fdae61",
  // 0.2: "#fee090",
  1.0: "#4575b4",
  0.4: "#4575b4",
  0.3: "#4575b4",
  0.2: "#4575b4",
  // 0.4: "#ffffbf",
  // 0.3: "#e0f3f8",
  // 0.2: "#abd9e9",
  0.1: "#4575b4",
  0.05: "#6694d1",
  0.01: "#6694d1",
  0.001: "#6694d1",
  0.0001: "#6694d1",
  0.0: "#4575b4"
};


var WindGL = function WindGL(gl) {
  this.gl = gl;
  this.fadeOpacity = 0.996; // how fast the particle trails fade on each frame
  this.speedFactor = 0.08; // 0.25; // how fast the particles move
  this.dropRate = 0.003; // how often the particles move to a random place
  this.dropRateBump = 0.01; // drop rate increase relative to individual particle speed
  this.eightVortices = [ // LUC
    0.3, 0.1,    0.1, 0.4,
    0.5, 0.5,    0.1, 0.7,
    0.7, 0.8,    0.3, 0.5,
    0.5, 0.2,    0.8, 0.3
  ];
  this.eightWeights1 = [
    2, 2, -2, 2
  ];
  this.eightWeights2 = [
    2, -2, -2, 2
  ];
  this.mouseData = [
    0.5, 0.5, // position
    2.0, 2.0  // weight
  ];
  this.drawProgram = createProgram(gl, drawVert, drawFrag);
  this.screenProgram = createProgram(gl, quadVert, screenFrag);
  this.updateProgram = createProgram(gl, quadVert, updateFrag);
  this.quadBuffer = createBuffer(gl, new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]));
  this.framebuffer = gl.createFramebuffer();
  this.setColorRamp(defaultRampColors);
  this.resize();
};

var prototypeAccessors = { numParticles: {} };

WindGL.prototype.resize = function resize () {
  var gl = this.gl;
  var emptyPixels = new Uint8Array(gl.canvas.width * gl.canvas.height * 4);
  // screen textures to hold the drawn screen for the previous and the current frame
  this.backgroundTexture = createTexture(gl, gl.NEAREST, emptyPixels, gl.canvas.width, gl.canvas.height);
  this.screenTexture = createTexture(gl, gl.NEAREST, emptyPixels, gl.canvas.width, gl.canvas.height);
};

WindGL.prototype.setColorRamp = function setColorRamp (colors) {
  // lookup texture for colorizing the particles according to their speed
  this.colorRampTexture = createTexture(this.gl, this.gl.LINEAR, getColorRamp(
    // colors
    palette2
  ), 16, 16);
};

prototypeAccessors.numParticles.set = function (numParticles) {
  var gl = this.gl;
  // we create a square texture where each pixel will hold a particle position encoded as RGBA
  var particleRes = this.particleStateResolution = Math.ceil(Math.sqrt(numParticles));
  this._numParticles = particleRes * particleRes;
  var particleState = new Uint8Array(this._numParticles * 4);
  for (var i = 0; i < particleState.length; i++) {
    particleState[i] = Math.floor(Math.random() * 256); // randomize the initial particle positions
  }
  // textures to hold the particle state for the current and the next frame
  this.particleStateTexture0 = createTexture(gl, gl.NEAREST, particleState, particleRes, particleRes);
  this.particleStateTexture1 = createTexture(gl, gl.NEAREST, particleState, particleRes, particleRes);

  var particleIndices = new Float32Array(this._numParticles);
  for (var i$1 = 0; i$1 < this._numParticles; i$1++) { particleIndices[i$1] = i$1; }
  this.particleIndexBuffer = createBuffer(gl, particleIndices);
};
prototypeAccessors.numParticles.get = function () {
  return this._numParticles;
};

WindGL.prototype.setConvolutionImage = function setConvolutionImage(windData, width, height) {
  let buffer = new ArrayBuffer(4 * width * height);
  let ubuf = new Uint8Array(buffer);
  const length = ubuf.length;
  let wd = windData.data;
  for (var i = 0; i < length; ++i) { // r, g, b, a
    ubuf[i] = wd[i] * 0.2;
  }

  this.convData = ubuf;
  this.convTexture = createTexture(this.gl, this.gl.LINEAR, ubuf, width, height);
};

WindGL.prototype.setWind = function setWind (windData) {
  this.windData = windData;
  this.windTexture = createTexture(this.gl, this.gl.LINEAR, windData.image);
};

WindGL.prototype.draw = function draw () {
  var gl = this.gl;
  gl.disable(gl.DEPTH_TEST);
  gl.disable(gl.STENCIL_TEST);
  bindTexture(gl, this.windTexture, 0);
  bindTexture(gl, this.particleStateTexture0, 1);
  bindTexture(gl, this.convTexture, 3);
  this.drawScreen();
  this.updateParticles();
};

WindGL.prototype.drawScreen = function drawScreen () {
  var gl = this.gl;
  // draw the screen into a temporary framebuffer to retain it as the background on the next frame
  bindFramebuffer(gl, this.framebuffer, this.screenTexture);
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  this.drawTexture(this.backgroundTexture, this.fadeOpacity);
  this.drawParticles();
  bindFramebuffer(gl, null);
  // enable blending to support drawing on top of an existing background (e.g. a map)
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  this.drawTexture(this.screenTexture, 1.0);
  gl.disable(gl.BLEND);
  // save the current screen as the background for the next frame
  var temp = this.backgroundTexture;
  this.backgroundTexture = this.screenTexture;
  this.screenTexture = temp;
};

WindGL.prototype.drawTexture = function drawTexture (texture, opacity) {
  var gl = this.gl;
  var program = this.screenProgram;
  gl.useProgram(program.program);
  bindAttribute(gl, this.quadBuffer, program.a_pos, 2);
  bindTexture(gl, texture, 2);
  gl.uniform1i(program.u_screen, 2);
  gl.uniform1f(program.u_opacity, opacity);
  gl.drawArrays(gl.TRIANGLES, 0, 6);
};

WindGL.prototype.drawParticles = function drawParticles () {
  var gl = this.gl;
  var program = this.drawProgram;
  gl.useProgram(program.program);
  bindAttribute(gl, this.particleIndexBuffer, program.a_index, 1);
  bindTexture(gl, this.colorRampTexture, 2);
  gl.uniform1i(program.u_particles, 1);
  gl.uniform1i(program.u_color_ramp, 2);
  gl.uniform1i(program.u_conv_texture, 3);
  gl.uniform1f(program.u_particles_res, this.particleStateResolution);
  gl.uniform2f(program.u_wind_res, this.windData.width, this.windData.height);
  gl.uniform2f(program.u_wind_min, this.windData.uMin, this.windData.vMin);
  gl.uniform2f(program.u_wind_max, this.windData.uMax, this.windData.vMax);
  gl.uniformMatrix4fv(program.luc, false, this.eightVortices);
  gl.uniform4fv(program.lucQ, this.eightWeights1);
  gl.uniform4fv(program.lucL, this.eightWeights2);
  gl.uniform4fv(program.mouseData, this.mouseData);
  gl.drawArrays(gl.POINTS, 0, this._numParticles);
};

WindGL.prototype.updateParticles = function updateParticles () {
  var gl = this.gl;
  bindFramebuffer(gl, this.framebuffer, this.particleStateTexture1);
  gl.viewport(0, 0, this.particleStateResolution, this.particleStateResolution);
  var program = this.updateProgram;
  gl.useProgram(program.program);
  bindAttribute(gl, this.quadBuffer, program.a_pos, 2);
  gl.uniform1i(program.u_particles, 1);
  gl.uniform1f(program.u_rand_seed, Math.random());
  gl.uniform2f(program.u_wind_res, this.windData.width, this.windData.height);
  gl.uniform2f(program.u_wind_min, this.windData.uMin, this.windData.vMin);
  gl.uniform2f(program.u_wind_max, this.windData.uMax, this.windData.vMax);
  gl.uniform1f(program.u_speed_factor, this.speedFactor);
  gl.uniform1f(program.u_drop_rate, this.dropRate);
  gl.uniform1f(program.u_drop_rate_bump, this.dropRateBump);
  gl.uniformMatrix4fv(program.luc, false, this.eightVortices);
  gl.uniform4fv(program.lucQ, this.eightWeights1);
  gl.uniform4fv(program.lucL, this.eightWeights2);
  gl.uniform4fv(program.mouseData, this.mouseData);
  gl.drawArrays(gl.TRIANGLES, 0, 6);
  // swap the particle state textures so the new one becomes the current one
  var temp = this.particleStateTexture0;
  this.particleStateTexture0 = this.particleStateTexture1;
  this.particleStateTexture1 = temp;
};

Object.defineProperties( WindGL.prototype, prototypeAccessors );

function getColorRamp(colors) {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  canvas.width = 256;
  canvas.height = 1;
  var gradient = ctx.createLinearGradient(0, 0, 256, 0);
  for (var stop in colors) {
    gradient.addColorStop(+stop, colors[stop]);
  }
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, 256, 1);
  return new Uint8Array(ctx.getImageData(0, 0, 256, 1).data);
}

// The palette can be easily tuned by adding colors.
var palette = [
  "#d73027",
  "#d73027",
  "#f46d43",
  "#f46d43",
  "#fdae61",
  "#fee090",
  "#ffffbf",
  "#e0f3f8",
  "#abd9e9",
  "#74add1",
  "#6694d1",
  "#4575b4"
];

// Draw objects
var af;
var DOMElement;


// Interaction objects
var isRightMouseDown = false;
var isLeftMouseDown = false;
var mouseRepulsionActive = false;
var mousePosition = [0, 0];

var windGL = null;
var heightVF = 180;
var widthVF = 360;
var staticVF = null;

var Windy =
{
  start: function(
    gl, element, nbParticles, imageDataSource, width, height)
  {
    this.end();
    DOMElement = element;
    // g = DOMElement.getContext("2d");

    // Internet Explorer

    windGL = new WindGL(gl);
    windGL.setConvolutionImage(imageDataSource, width, height);
    windGL.numParticles = nbParticles;
    staticVF = new Uint8Array(heightVF * widthVF);

    this.animate();

    let windy = document.getElementById('windy');
    windy.addEventListener('contextmenu', function(e) {e.preventDefault()});
    windy.addEventListener('mousedown', this.mouseDownCallback.bind(this));
    windy.addEventListener('mouseup', this.mouseUpCallback.bind(this));
    document.addEventListener('mousemove', this.mouseMoveCallback.bind(this));
    windy.addEventListener('mouseout', function() {mouseRepulsionActive = false}.bind(this));
  },

  end: function() {
    cancelAnimationFrame(af);
  },

  updateVF: function() {
    staticVF = new Uint8Array(heightVF * widthVF);
    var data = {
      width: DOMElement.offsetWidth ,
      height: DOMElement.offsetHeight ,
      uMin: 0, // -21.32,
      uMax: 30, // 26.8,
      vMin: 0, // -21.57,
      vMax: 30, // 21.42,
      image: staticVF
    };
    return data;
  },

  animate: function() {
    af = requestAnimationFrame(this.animate.bind(this));

    if (!windGL.windData) {
      var wd = this.updateVF();
      windGL.windData = wd;
    }
    if (windGL.windData) {
      windGL.draw();
    }
  },

  getEventPositionInCanvas: function(event, windyElement)
  {
    const rect = windyElement.getBoundingClientRect();
    const top = rect.top;
    const left = rect.left;
    const bot = rect.bottom;
    const w = rect.width;
    const h = bot-top;
    return [(event.clientX - left)/w, (10+event.clientY - top)/h];
  },

  mouseDownCallback: function(event) {
    if (isLeftMouseDown) {
      // This should be possible with alt-tab, maybe.
      console.log('[MouseDownCallBack]: multiple mousedown events ' +
        'without a mouseup.');
      return;
    }
    isLeftMouseDown = true;

    // Get coordinates for the click.
    // let positionInCanvas = this.getEventPositionInCanvas(event);
    // let sx = positionInCanvas[0];
    // let sy = positionInCanvas[1];

    // let rightclick =
      // event.which ? (event.which === 3) :
      // event.button ? event.button === 2 : false;

    // windGL.mouseData[0] = sx;
    // windGL.mouseData[1] = sy;
    windGL.mouseData[2] = Math.random() < 0.5 ? 2 : -2;
    windGL.mouseData[3] = Math.random() < 0.5 ? 2 : -2;

    // TODO augment weight of created vortex
    // TODO update shader param
  },

  mouseUpCallback: function(event) {
    mouseRepulsionActive = false;

    event.preventDefault();

    // windGL.mouseData[2] = 0;
    // windGL.mouseData[3] = 0;

    isLeftMouseDown = false;
    // TODO update shader param.
  },

  mouseMoveCallback: function(event) {
    const windyElement = document.getElementById('windy');
    if (!windyElement || !windGL) {return};
    // Prevent dragging the canvas
    event.preventDefault();

    // Get new pointer position.
    let positionInCanvas = this.getEventPositionInCanvas(event, windyElement);
    let sx = positionInCanvas[0];
    let sy = positionInCanvas[1];
    mousePosition = [sx, sy];

    windGL.mouseData[0] = sx;
    windGL.mouseData[1] = sy;
    windGL.mouseData[2] = windGL.mouseData[2] || 2;
    windGL.mouseData[3] = windGL.mouseData[3] || 2;

    // Check mouse status
    if (!isLeftMouseDown && !isRightMouseDown) {
      mouseRepulsionActive = true;
      return;
    }

    // TODO update shader param.
  }
};

export default Windy;
