<app-navbar [dashRoute]="dashRoute"></app-navbar>

<canvas width="1000" height="500" id="windy" class="col-12 flex-fill"></canvas>

<div *ngIf="dashRoute==='invalid'" class="dashother">
  <section>
    <div>
      <p><br/></p>
      <h5>Nothing here…</h5>
      <p>
        Please double-check the url. You can also take guesses or browse the web source to find hidden sections.
      </p>
    </div>
  </section>
</div>

<div *ngIf="dashRoute==='home'" id="dashhome">
<section>
  <div>
    <p><br/></p>
    <h5>Here you will find:</h5>
    <p>
      — Technichal ramblings on computer science and graphics<br/>
      — Art stuff, which may or may not be related to my journey since I started drawing in Jan. 2024<br/>
    </p>
    <p>
      Articles can be updated at a leisurely pace. As of 2024 this is still in the early stages.<br/>
      I’ll think of making an RSS feed if anyone is interested.
    </p>
  </div>
</section>
<section><div><br/></div></section>
<section>
  <div>
    <h5>Current cultural recs</h5>
    <p>These are some of my main references in recent years:</p>
    <p>Games:<br/>
      — Okami, Sekiro, Kingdom Hearts 2 & 3, NieR: Automata, NieR Replicant v.1.22<br/>
      — Last Guardian, ICO, Shadow of the Colossus<br/>
      — Stephen’s Sausage Roll, Braid, <a href="https://itch.io/games/tag-puzzlescript">puzzlescript stuff</a>, Brothers<br/>
    </p>
    <p>Books:<br/>
      — City of Dreaming Books, His Dark Materials, Book of Stars<br/>
      — Horde du Contrevent, Guerriers du Silence, Gagner la guerre<br/>
      — Killing Commendatore, Tao Te Ching (Le Guin), Tolkien’s stuff<br/>
      — Transformative works
    </p>
    <p>Animation:<br/>
      — Frieren, Mushishi, Made in Abyss, Samurai Champloo, Code Geass<br/>
      — Inu-oh, Mind Game, Kizumonogatari, Look Back, Maquia, Millennium Actress, Marnie<br/>
      — Wonderful Days, Summer Wars, Secret of Nimh, Secret of Kells<br/>
      
      <!-- — FMAB, Agartha, Howl’s Castle, Mononoke, Spirited Away, The Boy and the Heron, Your Name, Silent Voice, Akira, Ghost in the Shell<br/> -->
    </p>
    <!-- <p>Live action movies:<br/> -->
      <!-- — Kagemusha, Greenway’s The Cook, Hero, Harakiri, 2046 -->
      <!-- —  -->
    <!-- </p> -->
  </div>
</section>
</div>

<div *ngIf="dashRoute==='about'" id="dashabout">
  <section>
  <div id="home-pic">
    <img src="assets/img/me/me2.jpg"/>
  </div>

  <div id="aboot">
    <h4>Background</h4>
    <p>I am a soft­ware / research en­gi­neer with back­ground in soft­ware de­vel­op­ment, topol­ogy, graph­ics,
      vi­su­al­iza­tion and al­go­rithms. I completed my Ph.D. in Com­puter Sci­ence at
      <a href="http://www.sorbonne-universite.fr/">Sor­bonne Uni­ver­sity</a> in
      2019, under the supervision of <a href="https://julien-tierny.github.io/">Julien Tierny</a>
      and <a href="https://dblp.org/pers/p/Plainchault:M=eacute=lanie.html">Mélanie Plainchault</a>.
    </p><p>Before,
      I received a M.Sc. in Computer Science from <a href="https://en.wikipedia.org/wiki/CY_Tech">EISTI</a> in 2015; and
      have worked five years as a vi­su­al­iza­tion sci­en­tist, re­search en­gi­neer,
      and software developer at
      <a href="https://www.total.com/en">Total Energies</a>, in various domains:
      ma­chine learn­ing, mesh­ing, CFD, and topol­ogy.
    </p>
    <p>Since 2021, I have been working at <a href="https://en.wikipedia.org/wiki/Don%27t_Nod">
      Don’t Nod Entertainment</a> as a graphics engineer.
    </p>
  </div>
  </section>
  <div class="break-column">—</div>
  <section>
  <div id="interests">
    <h4>Research interests</h4>
    <p>I am keenly interested
      in <a href="https://en.wikipedia.org/wiki/Non-photorealistic_rendering">non-photorealistic rendering</a> and
      <a href="https://velocityskinning.com/">stylized animation</a> systems,
      especially in the context of game development,
      but also in illustration and <a href="https://www.sakugabooru.com/post">traditional animation</a> —
      I generally love to explore subjects that lie on the border between art and computer science.
    </p>
    <p>
      That said, I tend to be suspicious of data-driven methods. 
      In particular, I firmly argue against text-to-image or text-to-text AI content generation.
      My arguments are that 
      it wouldn’t be a thing if it was slower or more expensive than artists,
      whom it steals artworks and income from; and that
      it undermines human agency, autonomy and individuality — to say it in <a href="https://philpapers.org/rec/STIWIC-3">stieglerian</a>
      terms, it favors noetic entropy.
    </p>
    <p>
      I am also interested in software minimalism and getting things to work on the <a href="https://noclip.website/">web</a>.
    </p>
  </div>
  </section>
  <div class="break-column">—</div>
  <section>

  <div id="contact">
    <h4>Contact</h4>
    <p>
      <b>mail</b>: <span id="mail">moc(tod)liamg(ta)rls(tod)exm</span>
      <br/>
  </div>
  </section>
</div>

<div *ngIf="dashRoute==='pub'" id="dashpub">
  <section class="list">
    <h4>Peer-Reviewed Conference Papers</h4>
    <section class="item">
      <div class="thumb-pub">
        <a href="https://arxiv.org/abs/1908.07841"><img src="assets/img/craft/ranking.jpg"/></a>
      </div>
      <div class="detail-pub">
        <h6><a href="https://arxiv.org/abs/1908.07841">
          Ranking Viscous Finger Simulations to an Acquired Ground Truth with Topology-aware Matchings
        </a></h6>
        <p><b>M. Soler</b>, M. Petitfrere, G. Darche, M. Plainchault, B. Conche, J. Tierny
          <br/>
          IEEE Large Data Analysis and Visualisation, 2019
          <br/>
          <span class="award">
          <small>🏆</small> Best Paper Award
        </span>
        </p>
      </div>
    </section>
    <!-- -->
    <section class="item">
      <div class="thumb-pub">
        <a href="https://julien-tierny.github.io/stuff/papers/binMasood_topoInVis2019.pdf">
          <img src="assets/img/craft/ttk-overview.jpg"/></a>
      </div>
      <div class="detail-pub">
        <h6><a href="https://julien-tierny.github.io/stuff/papers/binMasood_topoInVis2019.pdf">
          An Overview of the Topology ToolKit
        </a></h6>
        <p>T. Bin Masood, J. Budin, M. Falk, G. Favelier, C. Garth, C. Gueunet,
          P. Guillou, L. Hofmann, P. Hristov, A. Kamakshidasan, C. Kappe, P. Klacansky,
          P. Laurin, J. A. Levine, J. Lukasczyk, D. Sakurai, <b>M. Soler</b>, P. Steneteg,
          J. Tierny, W. Usher, J. Vidal, M. Wozniak
          <br/>
          Topological Methods in Data Analysis and Visualization, 2019
        </p>
      </div>
    </section>
    <!-- -->
    <section class="item">
      <div class="thumb-pub">
        <a href="https://arxiv.org/abs/1808.05870"><img src="assets/img/craft/wass.jpg"/></a>
      </div>
      <div class="detail-pub">
        <h6><a href="https://arxiv.org/abs/1808.05870">
          Lifted Wasserstein Matcher for Fast and Robust Topology Tracking
        </a></h6>
        <p><b>M. Soler</b>, M. Plainchault, B. Conche, J. Tierny
          <br/>
          IEEE Large Data Analysis and Visualisation, 2018
          <br/>
        <span class="award">
          <small>🏆</small> Honorable Mention Award
        </span>
        </p>
      </div>
    </section>
    <!-- -->
    <section class="item">
      <div class="thumb-pub">
        <a href="https://arxiv.org/abs/1802.02731"><img src="assets/img/craft/zcompression.jpg"/></a>
      </div>
      <div class="detail-pub">
        <h6><a href="https://arxiv.org/abs/1802.02731">Topologically Controlled Lossy Compression</a></h6>
        <p><b>M. Soler</b>, M. Plainchault, B. Conche, J. Tierny
        <br/>IEEE Pacific Visualization Symposium, 2018</p>
      </div>
    </section>
  </section>

  <section class="list">
    <h4>Thesis</h4>
    <section class="item">
      <div class="thumb-pub">
        <a href="https://tel.archives-ouvertes.fr/tel-02171190"><img src="assets/img/craft/zthesis.jpg"/></a>
      </div>
      <div class="detail-pub">
        <h6><a href="https://tel.archives-ouvertes.fr/tel-02171190">
          Large Data Reduction and Structure Comparison with Topological Data Analysis
        </a></h6>
        <p><b>M. Soler</b><br/>
          advised by M. Plainchault (Total Energies) and <a href="https://julien-tierny.github.io/">J. Tierny</a>
          (CNRS, Sorbonne Université)
        <br/>2019</p>
      </div>
    </section>
  </section>

  <section class="list">
    <h4>Tutorials, Workshops</h4>
    <section class="item">
      <div class="thumb-pub">
        <a href="https://topology-tool-kit.github.io/stuff/ttk_tutorial_vis18.pdf">
          <img src="assets/img/craft/tuto.jpg"/>
        </a>
      </div>
      <div class="detail-pub">
        <h6><a href="https://julien-tierny.github.io/stuff/papers/binMasood_topoInVis2019.pdf">
          Topological Data Analysis Made Easy with the Topology ToolKit
        </a></h6>
        <p>G. Favelier, Ch. Gueunet, A. Gyulassy, J. Kitware, J. Levine, J. Lukasczyk,
          D. Sakurai, <b>M. Soler</b>, J. Tierny, W. Usher, Q. Wu
          <br/>
          IEEE VIS Tutorials, 2018
        </p>
      </div>
    </section>
    <!-- -->
    <section class="item">
      <div class="thumb-pub">
        <a href="https://journee-visu.github.io/documents/visu2019_Soler_Melanie_Plainchault.pdf">
          <img src="assets/img/craft/workshop-track.jpg"/>
        </a>
      </div>
      <div class="detail-pub">
        <h6><a href="https://journee-visu.github.io/documents/visu2019_Soler_Melanie_Plainchault.pdf">
          Suivi topologique rapide et robuste par appariement de Wasserstein augmenté
        </a></h6>
        <p><b>M. Soler</b>, M. Plainchault, B. Conche, J. Tierny
          <br/>
          Journée Visu, 2019
        </p>
      </div>
    </section>
    <!-- -->
    <section class="item">
      <div class="thumb-pub">
        <a href="http://visu2018.imag.fr/programme/visu02.pdf">
          <img src="assets/img/craft/workshop-comp.jpg"/>
        </a>
      </div>
      <div class="detail-pub">
        <h6><a href="http://visu2018.imag.fr/programme/visu02.pdf">
          Compression avec perte contrôlée par la topologie
        </a></h6>
        <p><b>M. Soler</b>, M. Plainchault, B. Conche, J. Tierny
          <br/>
          Journée Visu, 2018
        </p>
      </div>
    </section>
    <!-- -->

  </section>

</div>

<div *ngIf="dashRoute==='cv'" id="dashvita">
  <!-- -->
  <div>
    <h4>Employment</h4>
    <section class="item">
      <div class="date">
        2021 — 2024
        <!-- <p><i>(c) DNE, Florent Augy</i></p> -->
        <img src="assets/img/craft/banijusharm.jpg"/>
      </div>
      <div class="education-detail">
        <span class="education-title"><b>Graphics Engineer</b></span>
        <br/>
        <b>Don’t Nod Entertainment</b>
        <br/>
          Shipping of
          <a href="https://en.wikipedia.org/wiki/Harmony:_The_Fall_of_Reverie">Harmony: the Fall of Reverie</a>,
          <a href="https://en.wikipedia.org/wiki/Banishers:_Ghosts_of_New_Eden">Banishers: Ghosts of New Eden</a>,
          <a href="https://en.wikipedia.org/wiki/Jusant">Jusant</a>,
          <a href="https://en.wikipedia.org/wiki/Lost_Records:_Bloom_%26_Rage">Lost Records: Bloom & Rage</a>.
          More work on 3 unannounced projects.
          Implemented state-of-the art graphics features,
          ensured the stability, quality and performance of everything related to rendering.
        <br/>
        <span class="education-skill">Unreal 5, C++, D3D, 9th gen consoles, R&D.</span>
      </div>
    </section>
    <section class="item">
      <div class="date">
        2020
        <img src="assets/img/craft/spix-portals.jpg"/>
      </div>
      <div class="education-detail">
        <span class="education-title">Reconversion</span>
        <br/>
        <b>Self-taught</b>
        <br/>
          Covid time! After I completed my thesis, I took some time to dive into graphics and gamedev,
          with the goal to work in tighter relation to artistic endeavors.
          I built this website, a rough engine in Javascript, and produced one demo every month.
        <br/>
        <span class="education-skill">Graphics and Animation R&D, WebGL, game engine development, Web technologies.</span>
      </div>
    </section>
    <section class="item">
      <div class="date">
        2016 — 2019
        <img src="assets/img/craft/thes.jpg"/>
      </div>
      <div class="education-detail">
        <span class="education-title"><b>R&D Engineer, Ph.D. student</b></span> in Computer Science
        <br/>
        <b>Total Energies, Sorbonne University</b>
        <br/>
        Structure compression, tracking and comparison in large 3D data with Topological Data Analysis.
        Application to CFD simulations, open-source contributions to TTK.
        <br/>
        <span class="education-skill">Algorithms, Topology, 3D, C++, Python, VTK, Paraview, Blender.</span>
      </div>
    </section>
    <section class="item">
      <div class="date">
        2015 — 2016
        <img src="assets/img/craft/zhue.jpg"/>
      </div>
      <div class="education-detail">
        <span class="education-title"><b>R&D Engineer</b></span>, geosciences software
        <br/>
        <b>Total Energies</b>
        <br/>
        GUI/3D development and prototyping.
        <br/>
        <span class="education-skill">Java, 3D, JOGL, OIV, HueSpace.</span>
      </div>
    </section>
    <section class="item">
      <div class="date">
        2015
        <img src="assets/img/craft/zstg2.jpg"/>
      </div>
      <div class="education-detail">
        <span class="education-title"><b>Software engineering intern</b></span>, geosciences software
        <br/>
        <b>Total Energies</b>
        <br/>
        Prototyping and development of data models, APIs, visualization tools and topological algorithms for
        unstructured 3D meshes.
        <br/>
        <span class="education-skill">Java, 3D, JOGL, OIV, Topology, TDD.</span>
      </div>
    </section>
    <section class="item">
      <div class="date">
        2014
        <img src="assets/img/craft/zstg1.jpg"/>
      </div>
      <div class="education-detail">
        <span class="education-title"><b>Software engineering intern</b></span>, geosciences software,
        <br/>
        <b>Total Energies</b>
        <br/>
        AI tools prototyping for petrophysical data analysis: requirement gathering, research, validation,
        specification, algorithms / API / GUI development, unit testing.
        <br/>
        <span class="education-skill">Algorithms (Kohonen networks, clustering), Java, Matlab, TDD.</span>
      </div>
    </section>
  </div>
  <hr/>
  <!--  -->
  <div>
    <h4>Education</h4>
    <section class="item">
      <div class="date">
        2016 — 2019
      </div>
      <div class="education-detail">
        <span class="education-title"><b>Ph.D.</b></span>, Computer Science
        <br/>
        Sorbonne University, Paris
      </div>
    </section>
    <section class="item">
      <div class="date">
        2012 — 2015
      </div>
      <div class="education-detail">
        <span class="education-title"><b>Engineer’s degree (M. Sc.)</b></span>, Computer Science, valedictorian
        <br/>
        EISTI, Pau
      </div>
    </section>
    <section class="item">
      <div class="date">
        2010 — 2012
      </div>
      <div class="education-detail">
        French Classes Préparatoires aux Grandes Écoles, PCSI/PC*
        <br/>
        Selective curriculum in mathematics, physics and chemistry.
      </div>
    </section>
    <section class="item">
      <div class="date">
        2010
      </div>
      <div class="education-detail">
        French scientific Baccalauréat, summa cum laude
        <br/>
        Participation to IChO.
      </div>
    </section>
  </div>
  <hr/>
  <!-- -->
  <div>
    <h4>Honors and Awards</h4>
    <section class="item">
      <div class="date">
        2019
      </div>
      <div class="education-detail">
        <span class="education-title"><b>Best Paper</b></span>
        <br/>
        IEEE LDAV 2019
      </div>
    </section>
    <section class="item">
      <div class="date">
        2018
      </div>
      <div class="education-detail">
        <span class="education-title"><b>Best Paper Honorable Mention</b></span>
        <br/>
        IEEE LDAV 2018
      </div>
    </section>
    <section class="item">
      <div class="date">
        2015
      </div>
      <div class="education-detail">
        <span class="education-title"><b>Contest Winner</b></span>
        <br/>
        Total IT Student Contest, shared with Étienne Bec and Pierre Chat
      </div>
    </section>
  </div>
</div>

<div id="dash-links" class="container-fluid fixed-top">
  <div id="dashboard-links">
    <div class="row" id="pusher-wrapper">
      <div class="col-12" id="pusher">
        <img src="assets/img/frontpage/inkt.jpg" id="texture-windy"/>
      </div>
    </div>
  </div>
</div>
