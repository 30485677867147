import {Component, Inject, HostListener, OnDestroy, OnInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { ArticleService } from '../services/article.service';
import { Article } from '../model/article';
import { ParagraphType } from '../model/article';
import { Hyphenator, France } from '../app.hyphenation.js';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import hljs from 'highlight.js';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css']
})
export class ArticleDetailComponent implements OnInit, OnDestroy, AfterViewChecked {

  currentTitle = '';
  currentId = '';
  article: Article;
  paragraphTypes = ParagraphType;
  private subbed: Subscription;
  private highlighted: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService,
  ) {
    this.highlighted = false;
    this.subbed = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        this.currentId = this.route.snapshot.paramMap.get('id');
        this.getArticle();
      });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.subbed.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (!this.highlighted)
    {
      document.querySelectorAll('pre code').forEach((el) =>
      {
        let e = el as HTMLElement;
        hljs.highlightElement(e);
        this.highlighted = true; // at least one element was highlighted
      });
    }
  }

  getArticle(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.articleService.getArticle(id)
      .subscribe(article => {
        // this.article = article;
        const newArticle = new Article(article);
        const newTitle = France.apostrophize(article.title);
        newArticle.title = newTitle;

        if (!article.body) {
          this.article = newArticle;
          return;
        }

        const newBody = [];
        article.body.forEach(function(item) {
          const newItem = {};
          newItem['type'] = item.type;
          newItem['id'] = item.id;
          newItem['language'] = item.language;
          newItem['heading'] = item.heading;
          let newContent = '';

          switch (item.type) {
            case ParagraphType.Title:
            case ParagraphType.Subtitle:
            case ParagraphType.Paragraph:
              // item.content = France.franchize(item.content);
              newContent = France.apostrophize(item.content);
              newContent = Hyphenator.hyphenate(newContent, 'en');
              newContent = France.guillemetize(newContent);
              break;

            case ParagraphType.Quotation:
              newContent = France.apostrophize(item.content);
              newContent = France.guillemetize(newContent);
              newItem['origin'] = item['origin'];
              newItem['source'] = item['source'];
              break;

            case ParagraphType.ListItemFR:
              // item.content = Hyphenator.hyphenate(item.content, 'fr');
              newContent = France.franchize(item.content);
              // item.content = $sce.trustAsHtml(item.content);
              break;
            case ParagraphType.ListItem:
              newContent = France.apostrophize(item.content);
              // item.content = $sce.trustAsHtml(item.content);
              break;

            case ParagraphType.Code:
            case ParagraphType.CollapsibleCode:
              // item.content = item.content.replace(/</g, '&lt;');
              // item.content = item.content.replace(/>/g, '&gt;');
              // item.content = '<pre><code>' + item.content + '</code></pre>';
              // item.content = $sce.trustAsHtml(item.content);
              newContent = item.content;
              break;

            case ParagraphType.Equation:
              newContent = item.content;
              break;
            case ParagraphType.Link:
              newContent = item.content;
              break;
            case ParagraphType.TwoImages:
              newItem['image1'] = item['image1'];
              newItem['image2'] = item['image2'];
              newContent = item.content;
              break;

            default:
              newContent = item.content;
          }

          newItem['content'] = newContent;
          newBody.push(newItem);
        });

        newArticle.body = newBody;
        this.article = newArticle;
      });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const titles =
      document.getElementsByClassName('article-section-title');

    if (window.pageYOffset < 100) {
      this.currentTitle = '';
      return;
    }

    for (const t in titles) {
      if (titles.hasOwnProperty(t)) {
        const title = titles[t] as HTMLElement;
        const top = title.getBoundingClientRect().top;
        if (top <= 100) {
          this.currentTitle = title.innerText;
          this.currentId = title.id;
        }
      }
    }
  }
}
