import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ArticlesComponent } from './articles/articles.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';

// Style rendering
import { KatexModule } from './ngkatex/ng-katex.module';
import { NavbarComponent } from './navbar/navbar.component';

// Highlighting
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import cpp from 'highlight.js/lib/languages/cpp';
import glsl from 'highlight.js/lib/languages/glsl';

@NgModule({
    declarations: [
        AppComponent,
        ArticlesComponent,
        ArticleDetailComponent,
        DashboardComponent,
        NavbarComponent,
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        KatexModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: APP_INITIALIZER,
            useFactory:()=> {
                return () => {
                    hljs.registerLanguage('javascript', javascript);
                    hljs.registerLanguage('cpp', cpp);
                    hljs.registerLanguage('glsl', glsl);
                }
            },
            multi: true
        },
    ]
})
export class AppModule { }
