<div *ngIf="currentTitle" class="col-12 fixed-top" id="navbar">
  <div class="row col-12">
    <div class="col-2 hide-if-sm"></div>
    <div class="col-8">
      <nav>
        <span class="nobreak">
          <a (click)="scrollToTop()">↑   </a>
          <a>{{currentTitle}}</a>
        </span>
      </nav>
    </div>
  </div>
</div>

<div id="dashbar">
  <div id="titleblock">
    <div id="drtitle">
      <div id="drimg"><img src="assets/img/ico.ico"/></div>
      <div *ngIf="isShowcase()" id="drtext">
        Maxime Soler, Ph.D. — Software / R&D Engineer
      </div>
      <div *ngIf="isCorner()" id="drtext">
        Max.’s blog
      </div>
    </div>
  </div>
  <nav>
    <a *ngIf="isShowcase()"
        class="{{dashRoute === 'home' ? 'selected' : '' }}" routerLink="/tab/about">
      <div class="navtitle">About</div>
    </a>
    <a *ngIf="isShowcase()"
        class="{{dashRoute === 'cv' ? 'selected' : '' }}" routerLink="/tab/cv">
      <div class="navtitle">Vita</div>
    </a>
    <a *ngIf="isShowcase()"
        class="{{dashRoute === 'pub' ? 'selected' : '' }}" routerLink="/tab/pub">
      <div class="navtitle">Publications</div>
    </a>
    <a *ngIf="isShowcase()"
      class="{{dashRoute === 'craft' ? 'selected' : '' }}" routerLink="/articles/craft">
      <div class="navtitle">Showcase</div>
    </a>

    <a *ngIf="isCorner()"
        class="{{dashRoute === 'home' ? 'selected' : '' }}" routerLink="/tab/home">
      <div class="navtitle">Home</div>
    </a>
    <a *ngIf="isCorner()"
        class="{{dashRoute === 'technical' ? 'selected' : '' }}" routerLink="/articles/technical">
      <div class="navtitle">Tech blog</div>
    </a>
    <a *ngIf="isCorner()"
        class="{{dashRoute === 'art' ? 'selected' : '' }}" routerLink="/articles/art">
      <div class="navtitle">Art blog</div>
    </a>

    <a *ngIf="otherwise()"><div class="navtitle">???</div></a>
    <a *ngIf="otherwise()"><div class="navtitle">???</div></a>
    <a *ngIf="otherwise()"><div class="navtitle">???</div></a>
  </nav>
</div>

<div *ngIf="!currentTitle" class="col-12 fixed-top">
</div>
