import {Component, OnDestroy, OnInit} from '@angular/core';
import { Artitle } from '../model/artitle';
import { ArticleService } from '../services/article.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit, OnDestroy {

  articles: Artitle[];
  cat: string;
  catDesc: string;
  categories: string[][];
  private subbed: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService
  ) {
    this.subbed = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() =>
        this.articleService.getCategories()
          .subscribe((categories) => {
          this.categories = categories;
          this.getArticles();
        })
      );
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subbed.unsubscribe();
  }

  getArticles(): void {
    const cat = this.route.snapshot.paramMap.get('cat');
    this.cat = cat;
    const cats = this.categories;
    switch (cat) {
      case cats[0][0].toString():
        this.catDesc = cats[0][1];
        break;
      case cats[1][0]:
        this.catDesc = cats[1][1];
        break;
      case cats[2][0]:
        this.catDesc = cats[2][1];
        break;
      case cats[3][0]:
        this.catDesc = cats[3][1];
        break;
    }

    this.articleService.getArticles(cat)
      .subscribe(articles => {
        this.articles = articles;
      });
  }

}
