import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Article } from '../model/article';
import { Artitle } from '../model/artitle';

import { HttpClient } from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  private dbUrl = 'https://raw.githubusercontent.com/madblade/folio-db/master/db/';
  // private dbUrl = 'http://localhost:31338/';
  // private articlesUrl = 'all-titles.json';
  private categoriesUrl = 'categories.json';
  private isDevMode: boolean = false;

  constructor(
    private http: HttpClient,
  ) {}

  preventCache(): string {
    return '?nc=' + (new Date()).getTime().toString();
  }

  getCategories(): Observable<string[][]> {
    const url = this.dbUrl + this.categoriesUrl + this.preventCache();
    return this.http.get<string[][]>(url)
      .pipe(
        tap(() => this.log('fetched categories')),
        catchError(this.handleError('getCategories', [])));
  }

  getArticles(cat: string): Observable<Artitle[]> {
    const url = this.dbUrl + cat + '.json' + this.preventCache();
    return this.http.get<Artitle[]>(url)
      .pipe(
        tap(() => this.log('fetched articles')),
        catchError(this.handleError('getArticles', []))
      );
  }

  getArticle(id: number): Observable<Article> {
    const url = this.dbUrl + 'articles/' + id + '.json' + this.preventCache();
    return this.http.get<Article>(url)
      .pipe(
        tap(() => this.log(`fetched article ${id}`)),
        catchError(this.handleError<Article>('getArticle id=${id}'))
      );
  }

  private log(message: string) {
    if (this.isDevMode)
    {
      console.log(`ArticleService: ${message}`);
    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // [Enhancement] Should send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Should transform error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // [Enhancement] Let the app keep running by returning an empty result
      return of(result as T);
    };
  }

}
