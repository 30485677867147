export enum ParagraphType {
  Title,
  Subtitle,
  Paragraph,
  Link,
  Image,
  TwoImages,
  Quotation,
  Equation,
  Lettrine,
  Video,
  Code,
  CollapsibleCode,
  ListItemFR,
  ListItem,
  ClickableDemo
}

export class Article {
  id: number;
  title: string;
  date: string;
  thumb: string;

  type: string; // Text, Slides
  author: string;
  style: string;
  body:
    {
      id: number,
      type: ParagraphType,
      content: string,
      language: string,
      heading: string
    }[];

  constructor(copy) {
    this.id = copy.id;
    this.title = copy.title;
    this.date = copy.date;
    this.thumb = copy.thumb;

    this.author = copy.author;
    this.style = copy.style;
    this.body = copy.body;
  }
}
