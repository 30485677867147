import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnChanges {

  @Input() currentTitle: string;
  @Input() currentId: string;
  @Input() dashRoute: string;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() { }

  isShowcase()
  {
    const d = this.dashRoute;
    return (d&&(d==='about'||d==='cv'||d==='pub'||d==='craft')) ||
    (this.currentId&&this.currentId[0]==='1');
  }
  
  isCorner()
  {
    const d = this.dashRoute;
    return (d&&(d==='home'||d==='technical'||d==='art')) ||
      (this.currentId&&this.currentId[0]!=='1');
  }

  otherwise()
  {
    return !this.isShowcase()&&!this.isCorner();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
