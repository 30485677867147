<app-navbar
  [currentTitle]="currentTitle"
  [currentId]="currentId">
</app-navbar>

<div class="before-section"></div>
<section *ngIf="article" class="container nopadding nomargin article-detail-component">

  <!-- Head -->
  <div class="article-heading">
    <h1 class="article-view-title">
      <!-- Title -->
      <p class="padding-lr-20">{{article.title}}</p>
    </h1>
  </div>

  <!-- Body -->
  <div *ngIf="article.body" class="lead">
    <div *ngFor="let o of article.body" class="row">
      <div class="col-1"></div>
      <div class="col-10">

        <!-- Headings -->
        <h1 *ngIf="o.type==paragraphTypes.Title"
            class="article-section-title" [id]="o.id">
          <br/>{{o.content}}
        </h1>
        <h2 *ngIf="o.type==paragraphTypes.Subtitle"
            class="article-section-subtitle">
          {{o.content}}
        </h2>

        <!-- Body -->
        <p *ngIf="o.type==paragraphTypes.Paragraph"
           class="article-paragraph">
           <!-- TODO hyphenate -->
           <ng-katex-html [html]="o.content"></ng-katex-html>
        </p>
        <p *ngIf="o.type==paragraphTypes.ListItemFR"
           class="list-item" [innerHTML]="o.content">
        </p>
        <p *ngIf="o.type==paragraphTypes.ListItem"
           class="list-item-nofrench"
           [innerHTML]="o.content">
        </p>
        <a *ngIf="o.type==paragraphTypes.Link"
           [href]="o.content">
          {{o.heading}}
        </a>

        <!-- Quotations -->
        <div *ngIf="o.type==paragraphTypes.Quotation">
          <blockquote class="blockquote" >

            <p>{{o.content}}</p>
            <footer *ngIf="o.origin" class="blockquote-footer">{{o.origin}},
              <span *ngIf="o.source"><cite>{{o.source}}</cite></span>
            </footer>
          </blockquote>
        </div>

        <!-- Formulas -->
        <div *ngIf="o.type==paragraphTypes.Equation"
             class="katex-eqn">
          <ng-katex class="katex" [equation]="o.content"></ng-katex>
        </div>

        <!-- Media -->
        <img *ngIf="o.type==paragraphTypes.Image"
             [src]="o.content" class="article-image card-header"/>
        <div class="row" *ngIf="o.type==paragraphTypes.TwoImages" >
          <div class="col-6 image-left">
            <img [src]="o.image1" class="article-image card-header"/>
          </div>
          <div class="col-6 image-right">
            <img [src]="o.image2" class="article-image card-header"/>
          </div>
        </div>

        <div *ngIf="o.type==paragraphTypes.Video"
             class="article-video">
          <p style="color:red;">
            Error: video tag available on Chrome/Firefox.</p>
        </div>
        <div *ngIf="o.type==paragraphTypes.Video"
             class="article-video">
          <video width="100%" height="100%" controls>
            <source src="{{o.content}}"
                    type="video/webm; codecs=vp8,vorbis;">
            Your browser does not support the video tag.
          </video>
          <div><br/></div>
        </div>

        <!-- Code -->
        <div *ngIf="o.type==paragraphTypes.Code" class="artcode">
          <pre class="article-code {{o.language}}"><code>{{o.content}}</code></pre>
        </div>
      </div>

      <div class="col-lg-2"></div>
    </div>

    <!-- Author's mark -->
    <br/>
    <div class="row">
      <div class="col-9"></div>
      <div class="col-2 author-footer">
        <p>☐</p>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</section>
