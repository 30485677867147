import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { Article } from '../model/article';
import { ArticleService } from '../services/article.service';

import Windy from '../animation/wind/windy';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  timeout: number;
  timeoutLimit = 200;
  articles: Article[] = [];
  categories: string[][];
  dashRoute: string;
  glContext: CanvasRenderingContext2D | WebGLRenderingContext | null = null;
  imageWidth: number;
  imageHeight: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService,
  ) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() =>
        this.dashRoute = this.route.snapshot.paramMap.get('cat')
      );
  }

  ngOnInit() {
    this.getCategories();
  }

  ngOnDestroy() {
    Windy.end();
  }

  getCategories(): void {
    this.articleService.getCategories()
      .subscribe(categories => {
        this.categories = categories;
      });
  }

  @HostListener('window:resize') onResize() {
    this.tryToAnimate();
  }

  tryToAnimate(): void {
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(this.animatePart1.bind(this), this.timeoutLimit);
  }

  ngAfterViewInit(): void {
    this.animatePart1();
  }

  animatePart1(): void {
    // Warning! This is quick and dirty, be indulgent.
    const element = document.getElementById('windy') as HTMLCanvasElement;
    const hr = document.getElementById('dashbar');
    const hrStyle = window.getComputedStyle(hr);
    // const navHeight = hr.offsetHeight +
    //   parseInt(hrStyle.marginTop, 10) +
    //   parseInt(hrStyle.marginBottom, 10);
    // const winHeight = window.innerHeight;
    // const dashHeight = document
    //   .getElementById('dashboard-links').clientHeight;
    // const paddingBottom = 20;
    const width = element.offsetWidth;
    const height = width / 1.87; // winHeight - navHeight - dashHeight - paddingBottom;
    element.style.height = height.toString();

    const nbSamples = 15000;
      // Math.floor(Math.min(25 * width * height / 2000, 100000));
    console.log('[Dashboard] Particle tracing starting using ' +
      nbSamples + ' samples.');

    element.setAttribute('width', width.toString());
    element.setAttribute('height', height.toString());
    document.getElementById('dash-links').style.top = hrStyle.top;
    document.getElementById('dash-links').style.marginTop = '10px';
    // document.getElementById('dash-links').style.width = hrStyle.width;

    // const wrapper = document.getElementById('pusher-wrapper');
    // const mix = Math.min(0, Math.max(150, height));
    // wrapper.setAttribute('style', 'height: ' + mix.toString() + 'px; ' +
    //   '');
      // 'width: ' + trueWidth + 'px;');

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    let myData = null;
    if (!myData) {
      const img = document.getElementById('texture-windy') as HTMLCanvasElement;
      this.imageWidth = img.width;
      this.imageHeight = img.height;
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0 );
      if (img.width === 0) {
        myData = null;
      } else {
        try {
          myData = context.getImageData(0, 0, img.width, img.height);
        } catch (error) {
          myData = null;
        }
      }
    }

    if (myData && myData.data[0] !== 0) {
      this.animatePart2(element, nbSamples, myData);
    } else {
      console.log('[Dashboard] The browser did some fancy stuff that prevented me from getting that ' +
        'blasted image. Trying again later.');
      this.tryToAnimate();
    }
  }

  animatePart2(element, nbSamples, myData): void {

    let gl = this.glContext;

    // Firefox, Chrome
    if (!gl) {
      gl = element.getContext('webgl', { antialiasing: true });
    }

    // Unsupported
    if (!gl) {
      if (!document.getElementById('webgl-unsupported')) {
        const image = document.createElement('div');
        image.setAttribute('id', 'webgl-unsupported');
        image.setAttribute('style', 'position: absolute;left:0;');
        image.innerText = 'Your browser does not support WebGL :/';
        document.getElementById('pusher').appendChild(image);
      }
      console.log('failed');
      return;
    }

    this.glContext = gl;
    Windy.start(
      gl, element, nbSamples, myData, this.imageWidth, this.imageHeight
    );
  }
}
