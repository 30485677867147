<app-navbar [dashRoute]="cat"></app-navbar>
<br/>
<div class="articles col-12">
  <section *ngFor="let article of articles" routerLink="/detail/{{article.id}}">
    <div *ngIf="cat=='craft'" class="trailer-container">
      <img src="{{article.thumb}}" alt="no picture"/>
    </div>
    <div class="trailer-title">
      <span class="badge articles-single-article-date">{{article.date}}</span>
      <span class="articles-single-article-title">
        <a routerLink="/detail/{{article.id}}">{{article.title}}</a>
      </span>
    </div>
  </section>
</div>
<div id="afterquote">
  <div *ngIf="cat==='craft'">
    <blockquote class="blockquote text-center">
      <p class="mb-0">Variables are the spice of life</p>
      <footer class="blockquote-footer">U. K.
        Le Guin<span>, <cite>The Language of the Night</cite></span>
      </footer>
    </blockquote>
  </div>
</div>
